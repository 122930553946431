import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import FarmerBg from "../images/static-only/visit-one-of-our-farms.jpg"
import BabyBg from "../images/static-only/babys-development/babys-dev-menu.jpg"
import ScienceBg from "../images/static-only/the-science-menu.jpg"

var farmerBg = {
  backgroundImage: `url(${ FarmerBg })`
}

var babyBg = {
  backgroundImage: `url(${ BabyBg })`
}

var scienceBg = {
  backgroundImage: `url(${ ScienceBg })`
}

/* Event Experience structure > Large menu items */

class EventExperience extends React.Component {
  componentDidMount() {
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Event Experience</title>
        </Helmet>
        <main role="main">

          <section className="top-level-menu-list">
            
            <div className="top-level-menu-list__item" style={farmerBg}>
              <span className="tl-menu-item__title">Visit One Of Our <strong>Farms</strong></span>
              <Link to="/event-experience/grazing" className="cta -gold-gradient">Read more</Link>
            </div>

            <div className="top-level-menu-list__item -light-bg" style={babyBg}>
              <span className="tl-menu-item__title">Explore Baby's <strong>Development</strong></span>
              <Link to="/event-experience/explore-babys-development" className="cta -gold-gradient">Read more</Link>
            </div>

            <div className="top-level-menu-list__item" style={scienceBg}>
              <span className="tl-menu-item__title">Learn About Our <strong>Ingredients</strong></span>
              <Link to="/event-experience/learn-about-our-ingredients" className="cta -gold-gradient">Read more</Link>
            </div>

          </section>

        </main>
      </Layout>
    )
  }
} 

export default EventExperience